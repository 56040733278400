<route>
{
  "meta": {
    "permission": [
      "auth.view_user"
    ]
  }
}
</route>

<template>
  <i-table
    api="user"
    app="auth.user"
    :otherBtn="userBtn.view"
    :title="$tc('user', 1)"
    :headers="headers"
    :otherPermission="userBtn.edit && userBtn.rol"
    :opt="isAdmin ? null : { company: this.firstCompany }"
    @click:create="changeRoute({ id: 'create' })"
    @click:edit="changeRoute($event, true)"
  >
    <template v-slot:otherBtn="{ item }">
      <i-btn
        classes="mx-1"
        color="info"
        icon="fas fa-eye"
        outlined
        :title="$t('see')"
        @click="changeRoute(item)"
      />
    </template>
    <template v-slot:item.state="{ item }">
      {{ item.profile ? item.profile.state : '' }}
    </template>
    <template v-slot:item.groups="{ value }">
      <div class="pt-1">
        <span v-for="(group, i) in value" :key="`group-${i}`">
          <v-chip v-if="i < 5" class="mb-1 mr-1" small>
            {{ group.name }}
          </v-chip>
          <span v-else class="font-weight-black">...</span>
        </span>
      </div>
    </template>
    <template v-slot:item.get_all_permissions="{ value }">
      <span v-show="value.length === 0"></span>
      <span v-for="(permission, i) in value" :key="`permision-${i}`">
        <v-chip v-if="i < 5" class="mr-1 mb-1" small color="primary">
          {{ permission }}
        </v-chip>
        <span v-if="i === 5" class="font-weight-black"> ... </span>
      </span>
    </template>
  </i-table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      firstCompany: 0
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('username', 1),
          value: 'username'
        },
        {
          text: this.$t('first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'last_name'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.userBtn.rol ? this.$tc('roles', 1) : '',
          value: this.userBtn.rol ? 'groups' : ''
        },
        {
          text: this.userBtn.permissions ? this.$tc('permissions', 1) : '',
          value: this.userBtn.permissions ? 'get_all_permissions' : ''
        },
        {
          text: this.userBtn.edit || this.userBtn.view ? this.$t('action') : '',
          value: this.userBtn.edit || this.userBtn.view ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    }),
    userBtn() {
      return {
        rol: this.getPermissions([`auth.view_group`]) || this.isAdmin,
        permissions:
          this.getPermissions([`auth.view_permission`]) || this.isAdmin,
        edit: this.getPermissions([`users.change_profile`]) || this.isAdmin,
        view: this.getPermissions([`users.view_profile`]) || this.isAdmin
      }
    }
  },
  methods: {
    /**
     * setCompany
     * Método para asignar la compañía a la que pertenece el usuario al
     * filtro de la empresa de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    },
    changeRoute(item, needEdit = false) {
      this.$router.push({
        name: 'users-pk',
        params: {
          pk: this.codification({ code: item.id }),
          edit: needEdit
        }
      })
    }
  },
  created() {
    this.setCompany()
  }
}
</script>
